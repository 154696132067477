import React /*, { useEffect, useState } */ from "react"
// import megaMenuOneLevel from '../../components/dashibiden/dynamicMenu/__fixtures__/megaMenuOneLevel.json'
// import getRdfsMenu from '../../components/dashorm/menuHelpers//menuBuilderUtils/getRdfsMenu.js'
// import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
// import DashMenu from '../../components/dashorm/menuHelpers/DashMenu.js'

// local test url : http://localhost:1111/devTests/MegaMenuOneLevelTest/


export default function MegaMenuOneLevelTest() {
  // const [menuTree, setMenuTree] = useState([])

  // const items = megaMenuOneLevel.data.allRdfProperty.nodes
  // // items.splice(10, items.length)

  // const branches = [
  //   { nodes: items, title: 'Les classes', icon: DoubleArrowIcon, fallbackPage: 'test:test' }
  // ]
  // // const menuTree = await getRdfsMenu(branches)

  // // for async function call
  // useEffect(() => {
  //   async function getMenuTree() {
  //     const menuTree = await getRdfsMenu(branches)
  //     setMenuTree(menuTree)
  //   }
  //   getMenuTree()
  //   // do not really understand this for now.
  //   //   documentation : https://bobbyhadz.com/blog/react-hook-useeffect-has-missing-dependency
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <div>
      <p>@TODO: result of "big migration", restore or delete it</p>
      {/* <h1>Tree to test : </h1>
      <DashMenu menuTree={menuTree} /> */}
    </div>
  )
}
